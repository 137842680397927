



























import {Component, Vue} from 'vue-property-decorator'
import {AllWorkspaceInvoices} from "@/graphql/WorkspaceSubscription";
import Template from "@/components/templates/Template.vue";

@Component({
  components: {Template}
})

export default class Invoices extends Vue {
  page: Number = 1;
  invoices: Array<any> = []


  get workspace() {
    return this.$store.state.workspace;
  }

  created() {
    this.allInvoices();
  }

  allInvoices() {
    this.$apollo.query({
      query: AllWorkspaceInvoices,
      variables: {
        workspace_id: this.workspace.id,
        page: this.page
      }
    }).then((res: any) => {
      this.invoices = res.data.invoices.data;
    })
  }

}

