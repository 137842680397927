









































































import {Component, Vue, Prop} from 'vue-property-decorator';
import Downgrade from "@/components/subscription/Downgrade.vue";
import { CreateFeedbackMutation } from '@/graphql/feedback';
import { feedback_types } from "@/graphql/feedback";
import { UpdateUserMutation, MeQuery } from "@/graphql/auth";


@Component({
  apollo: {
    feedback_types: {
      query: feedback_types,
      result(_result) {
        this.feedbackTypeId = _result.data.feedback_types[0].id;
      }
    },
  },
  components: {Downgrade},
})
export default class NewPlanSelector extends Vue {
  step: number | null = null;
 


  productId: string = '8aa3b033-8134-4532-b5ac-e4bf98b561c8';

  feedbackDescription: string = "User: " + this.me.name + "  Email: " + this.me.email + " User: " + this.me.name + "  Email: " + this.me.email + " Total activites: " + this.activitesCreated + " Account created: " + this.me.created_at
  response: string = "";
  feedbackTypeId: string = ""

  @Prop({required: false, default: false})
  secondOffer!: boolean;
  
  @Prop({required: false, default: false})
  fromSidebar!: boolean;
  


  get me() {
    return this.$store.state.me;
  }



  get activitesCreated() {
    return this.workspace.totalObjectivesCount
  }

  PremiumBenefits: Array<string> = [
    'All features, unlimited',
    'Get coaching from our team',
    'Prioritized new feature requests',
    'Personalized onboarding with our CEO',
    'Early access to beta features',
    '50% discount on plan',
  ];

  get workspace() {
    return this.$store.state.workspace;
  }

  get workspaceId() {
    return this.$store.state.workspace.id;
  }

  get meId() {
    return this.$store.state.me.id;
  }

  get showFirstOffer() {
    return this.$store.state.show_first_offer;
  }

 

  closeModal() {
    // @ts-ignore
    this.$parent.close() 
    if(!this.fromSidebar) {
      this.$store.commit('set_show_first_offer', null);
    }
  }

  handleBasicClick() {
    this.$gtag.event('pmb_user_picked_basic_plan');
    if(this.fromSidebar) {
      this.$store.commit('set_coupon_code', '');
      this.$router.push({name: 'workspace-checkout', params: {productId: "648f3a46-d5cc-48da-8a3c-a0c4e0f50a87", billingPeriod: "monthly"}});
      this.closeModal()
    } else {
      if(this.secondOffer) {
        this.setUserAccess()
        this.submitFeedback("User has been downgraded to freemium",);
        this.closeModal();
      } else {
        this.step = 1;
      }
      
    }
  
  }

  handleGoBack() {
    this.step = null;
  }

  setUserAccess() {
    this.$apollo
      .mutate({
        mutation: UpdateUserMutation,
        variables: {
          id: this.meId,
          has_freemium_access: true,
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: MeQuery }],
      })
      .then(() => {
        this.$buefy.toast.open({
          message: 'Account downgraded',
          position: 'is-bottom-right',
          type: 'is-black',
        });
        if(this.secondOffer) {
          this.submitFeedback("User has been downgraded to freemium",);
        }
        this.closeModal();
      })
      .catch(error => {
        console.error('Failed to update user access:', error);
        this.$buefy.toast.open({
          message: 'Failed to update access. Please contact support.',
          type: 'is-danger',
        });
      });
  }

  submitFeedback(title: string) {
    this.$apollo
      .mutate({
        mutation: CreateFeedbackMutation,
        variables: {
          creator_id: this.meId,
          workspace_id: this.workspaceId,
          feedback_type_id: this.feedbackTypeId,
          title: title,
          description: this.feedbackDescription
        },
      })
      .then(() => {
        this.feedbackDescription = "";
      });
  }

  upgrade() {
    if (this.fromSidebar) {
      this.submitFeedback("User has accepted Premium trial from sidebar",);
    } else if (!this.fromSidebar && this.showFirstOffer) {
      this.submitFeedback("User has accepted Premium trial");
      this.$store.commit('set_show_first_offer', null);
    } else if (!this.fromSidebar && !this.showFirstOffer) {
      this.submitFeedback("User has accepted Premium trial from second offer"); 
    }
    this.$gtag.event('pmb_user_picked_premium_plan');
    this.$store.commit('set_coupon_code', 'EARLYBIRD50');
    this.$router.push({name: 'workspace-checkout', params: {productId: "648f3a46-d5cc-48da-8a3c-a0c4e0f50a87", billingPeriod: "monthly"}});
    this.closeModal()
  }

  mounted() {
    this.$gtag.event('pmb_plan_selector_offer_mounted');
  }
}
