





























































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {NorthStar, Objective} from "@/typescript/types";
import NorthStarService from "@/NorthStarService";

@Component({
  components: {},
})
export default class AddOrLinkNorthStar extends Vue {
  createNorthStarInput: string | null = null;
  service: NorthStarService | null = null;
  selectedNorthStar: NorthStar | null = null;
  allowConnectWithWorkspace: boolean = false;
  submitted: boolean = false;
  getNorthStars: NorthStar[] = [];
  open: boolean = false;

  selectedColor: string | null = null;
  colors: Array<string> = [
    '#773f75',
    '#bd5fb4',
    '#374ea9',
    '#776015',
    '#7b7343',
    '#316863',
    '#2e825f',
    '#654C4F',
    '#5B507A',
    '#546356',
    '#1F4730',
    '#642B2C',
    '#2E294E',
    '#820263',
    '#64024C',
  ];

  backgrounds: Array<string> = [
    'https://images.unsplash.com/photo-1577398628395-02eb1c5bcf6b?q=80&w=300',
    'https://images.unsplash.com/photo-1604782206219-3b9576575203?q=80&w=300',
    'https://images.unsplash.com/photo-1604079628040-94301bb21b91?q=80&w=300',
    'https://images.unsplash.com/photo-1604076947037-d909e2f10fca?q=80&w=300',
    'https://images.unsplash.com/photo-1516796181074-bf453fbfa3e6?q=80&w=300',
    'https://images.unsplash.com/photo-1533158326339-7f3cf2404354?q=80&w=300',
    'https://images.unsplash.com/photo-1579546928686-286c9fbde1ec?q=80&w=300',
    'https://images.unsplash.com/photo-1504548840739-580b10ae7715?q=80&w=300',
  ];


  selectedSuggestions: Array<string> = [];
  selectedObjectiveSuggestions: Array<{northstarId: string, selectedObjectives: Array<string>}> = [];

  @Prop({required: true})
  origin!: String;

  @Prop({required: false, default: new Date()})
  startDate!: Date

  @Prop({required: false, default: false})
  inModal!: boolean;

  mounted() {
    if(this.$props.origin == 'board') {
      this.board.northStars.forEach((item: NorthStar) => {
        this.selectedObjectiveSuggestions.push({northstarId: item.id, selectedObjectives: []})
        if(item.northstar_copied_from_id){
          this.selectedSuggestions.push(item?.northstar_copied_from_id)
        }

      })
    }

    this.service = new NorthStarService(this.$apollo);
    //this.preSetColor();
    this.$nextTick(function () {
      //@ts-ignore ToDo: fix type issue
      this.$refs.northStarCreate.focus();
    });
  }


  getRandomItem(id: string): string {
    // use the last 3 characters of the id as the seed
    const seed = parseInt(id.substr(id.length - 3), 16);
    // generate a random number between 0 and 1
    let random = (seed * 9301 + 49297) % 233280;
    let rnd = random / 233280;
    // use this random number to return an item from the array
    return this.backgrounds[Math.floor(rnd * this.backgrounds.length)];
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get board() {
    return this.$store.state.board;
  }

  get northStars() {
    return this.$store.state.workspace.northStars;
  }

  get finalColor() {
    if(this.selectedColor) {
      return this.selectedColor;
    } else if(this.$props.origin == 'board' && this.suggestColorFromNsCount) {
      return this.suggestColorFromNsCount;
    } else {
      return null;
    }
  }

  get suggestedNorthstars() {
    if(this.board.board_template.northStars.length > 0) {
      return this.board.board_template.northStars.filter((northstar: NorthStar) => {
        return northstar;
        /*
        if(!this.selectedSuggestions.includes(northstar.id)) {
          return northstar;
        }

         */
      })
    }
    return [];
  }

  checkId(id: string): boolean {
    return this.selectedSuggestions.includes(id);
  }

  reset() {
    this.createNorthStarInput = null;
    this.selectedColor = null;
    this.submitted = false;
  }

  get suggestColorFromNsCount() {
    let count = this.$store.state.board.northStars.length;
    if(count === 0) {
      return '#773f75';
    } else if(count === 1) {
      return '#4a677d';
    } else if(count === 2) {
      return '#7b7343';
    } else {
      return null;
    }
  }

  setFromSuggestion(suggestion: string) {
    this.createNorthStarInput = suggestion;
    this.createOrAddNorthStar();
  }

  openFromWorkspaceList() {
    this.open = true;
    //@ts-ignore ToDo: fix type issue
    this.$refs.dropdown.toggle();
  }

  createOrAddNorthStar(): void {
    this.$gtag.event('pmb_create_ns');
 
    this.submitted = true;
    if(!this.selectedColor) {
      const randomIndex = Math.floor(Math.random() * this.colors.length);
      this.selectedColor = this.colors[randomIndex];
    }
    if (this.selectedNorthStar) {
      this.addNorthStar(this.selectedNorthStar)

      return;
    }
    
    if (this.origin === 'board') {
      this.service?.createNorthStar(this.workspace, this.board, this.createNorthStarInput!, this.$props.startDate, this.$props.startDate , this.finalColor).then((result: any) => {
        this.$emit('completed', true)
        this.$store.commit('set_newly_created_northstar_count', (this.$store.state.newlyCreatedNorthstarsCountInWorkspace == null) ? 1 : this.$store.state.newlyCreatedNorthstarsCountInWorkspace + 1)
        this.$store.commit('set_newly_created_northstar_in_board', result.data.createNorthStar.id);
        this.$gtag.event('pembio_create_north_star');
        this.reset();
        this.$events.fire('new_northstar_created', result.data.createNorthStar)
        if(this.$props.inModal) {
          //@ts-ignore
          this.$parent.close();
        }

      })
    } else if(this.origin == 'workspace') {
      this.service?.createNorthStarWithoutBoard(this.workspace, this.createNorthStarInput!, null, this.$props.startDate, this.finalColor).then((result: any) => {
        this.$emit('completed', true)
        this.$store.commit('set_newly_created_northstar_count', (this.$store.state.newlyCreatedNorthstarsCountInWorkspace == null) ? 1 : this.$store.state.newlyCreatedNorthstarsCountInWorkspace + 1)
        this.$events.fire('new_northstar_created', result.data.createNorthStar)
        this.$gtag.event('pembio_create_north_star');
        if(this.$props.inModal) {
          //@ts-ignore
          this.$parent.close();
        }
      })
    }
    this.createNorthStarInput = null;
    this.$emit('northstar-added', true);
  }

  addNorthStar(northStar: NorthStar): void {
    this.selectedNorthStar = null;
    let northStarBoards = [...northStar.boards,this.board];
    this.service?.addBoardToNorthStar(northStarBoards, northStar).then(() => {
      this.$emit('completed', true)
      if(this.$props.inModal) {
        //@ts-ignore
        this.$parent.close();
      }
    })
  }

  setNorthStar(northStar: NorthStar) {
    this.selectedNorthStar = northStar;
    this.createNorthStarInput = northStar.name
  }

  colorSelected(color: string) {
    if (color === 'No color') {
      this.selectedColor = null;
    } else {
      this.selectedColor = color;
    }
  }

  copyNorthstar(northStarId: string) {
    if(!this.checkId(northStarId)) {
      this.selectedSuggestions.push(northStarId);
      this.$events.fire('added-suggested-northstar');
      this.service?.copyNorthStar(northStarId, this.board.id, this.workspace.id, 'not_all').then((_) => {
        if(this.inModal) {
          //@ts-ignore
          this.$parent.close();
        }
      })
    } else {
      this.$buefy.toast.open({
        message: "You've already added this",
        position: 'is-bottom-right',
        type: 'is-black',
      });
    }
  }

  ifAlreadySelected(northStarId: string) {
    if(this.selectedSuggestions.includes(northStarId)){
      return true;
    }
    return false;
  }

  getCopiedNorthstarId(northstar_id: string) {

    let copiedNorthstar = this.board.northStars.filter((northstar: NorthStar) => {
      return northstar.northstar_copied_from_id == northstar_id;

    });

    return copiedNorthstar[0].id;
  }


  getUnselectedObjectives(northstar_id: string) {
    let selectedObj = this.selectedObjectiveSuggestions.filter((item: any) => {
      if(item.northstarId === northstar_id) {
        return item;
      }
    })


    let northstar = this.board.board_template.northStars.filter((northstar: NorthStar) => {
      if(northstar.id === northstar_id){
        return northstar;
      }
    });

    let objectives = northstar[0].objectives?.filter((objective: Objective) => {
      if(selectedObj.length > 0 ) {
        if(!selectedObj[0].selectedObjectives.includes(objective.id)){
          return objective;
        }

      }
      return objective;
    })

    return objectives;

  }



  copyObjective(objective_id: string, northstar_id: string) {
    this.selectedObjectiveSuggestions.find((items: any) => {
      if(items.northstarId === northstar_id) {
        return items.selectedObjectives.push(objective_id);
      }
    })
    this.service?.copyObjectiveForNorthStar(northstar_id, objective_id, this.$store.state.board.id).then((res: any) => {
      this.$events.fire('objective-created-from-northstar', {objective: res.data.copyObjectiveForNorthstar, northStarId: northstar_id });
    });
  }
}
