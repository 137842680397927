import gql from "graphql-tag";

export const WorkspaceNotificationFragment = gql`
    fragment WorkspaceNotification on WorkspaceNotification {
        id
        board {
            id
            pid
        }
        user {
            id
        }
        workspace {
            id
        }
        data {
            message
        }
        read
        
    }
`;

export const WorkspaceNotificationsForBoardQuery = gql`
    query WorkspaceNotificationsForBoard($id: ID!) {
        getWorkspaceNotificationsForBoard(board_id: $id) {
            data {
                ...WorkspaceNotification
            }
            paginatorInfo {
                total
                currentPage
                hasMorePages
            }
        }
    }
    ${WorkspaceNotificationFragment}
`;

export const ReadAllWorkspaceNotificationsForBoard = gql`
    mutation ReadAllWorkspaceNotificationsForBoard($id: ID!) {
        readAllWorkspaceNotificationsForBoard(board_id: $id)
    }
`;

export const WorkspaceNotificationCreatedSubscription = gql`
    subscription WorkspaceNotificationCreated($workspace_id: ID!) {
        workspaceNotificationCreated(workspace_id: $workspace_id) {
            ...WorkspaceNotification
        }
    }
    ${WorkspaceNotificationFragment}
`;

export const GetAllWorkspaceNotificationForUser = gql`
  query AllWorkspaceNotificationForUser($id: ID!) {
    getAllWorkspaceNotifications(workspace_id: $id) {
        ...WorkspaceNotification
    }
  }
  ${WorkspaceNotificationFragment}
`;

