






































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import {Workspace} from "@/typescript/types";
import UserStatus from "@/components/user/UserStatus.vue";
import UserProfile from "@/components/user/profile/UserProfile.vue";
import MyWorkspacesList from "@/components/navigation/MyWorkspacesList.vue";
import Paywall from "@/components/subscription/Paywall.vue";

const WorkspaceAvatar = () => import("@/components/workspace/WorkspaceAvatar.vue")
const UserSidebar = () => import("@/components/navigation/UserSidebar.vue")
const MemberListItem = () => import("@/components/navigation/MemberListItem.vue")
const WorkspaceMenu = () => import("@/components/navigation/WorkspaceMenu.vue")
const PomodoroTimer = () => import("@/components/user/PomodoroTimer.vue")
const UserAvatar = () => import("@/components/user/UserAvatar.vue")
const WorkspaceSidebar = () => import("@/components/navigation/WorkspaceSidebar.vue")
const OnboardingChecklistButton = () => import("@/components/OnboardingChecklistButton.vue")
const OnboardingChecklist = () => import("@/components/OnboardingChecklist.vue")


@Component({
  components: {
    UserAvatar,
    PomodoroTimer,
    MyWorkspacesList,
    WorkspaceMenu,
    MemberListItem,
    WorkspaceAvatar,
    UserSidebar,
    OnboardingChecklistButton,
    WorkspaceSidebar,
    OnboardingChecklist
  },
})

export default class WorkspaceSwitcher extends Vue {
  sliceAmount: number = 0;
  showingMoreWorkspaces: boolean = false;
  paginateWithCount: number = 5;
  currentPaginationCount: number = 0;
  activeTab: number = 0;
  dynamicTooltip: string = "";
  popped: boolean = true;
  activeBox: string | null = null;
  manualOpen: boolean = false;
  fadeOutNotification: boolean = false;
  canAddNewWorkspace: boolean = false;

  get me() {
    return this.$store.state.me;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get isAdminOfWorkspace() {
    return this.$store.getters.isAdminOfWorkspace;
  }

  get currentWorkspacePid() {
    return this.$store.state.workspace.pid;
  }


  get isPremium() {
    if(this.$store.state.workspace.currentSubscription) {
      return true
    } else { return false }
  }

  get workspaceMembersCount() {
    return this.$store.state.workspace.members.length;
  }

  get onboarding() {
    return this.$store.state.expanded_onboarding;
  }

  get showChecklistPrompt() {
    return this.$store.state.show_checklist_prompt;
  }

  get showOfferNotificationbar() {
    return this.$store.state.show_initial_offer_notification_bar
  }
  get showNotificationbar() {
    return this.$store.state.notification_bar_active
  }

  get onGuestAccount() {
    return this.$store.state.me.isFake;
  }

  get showActivityProgress() {
    return this.$store.state.show_activity_progress;
  }


  get workspaceNotification() {
    return this.$store.state.workspace_notification;
  }

  get currentWorkspaceNotInFirstThree(): Workspace | undefined {
    const firstThreePids = this.me.workspaces.slice(0, 3).map((w: Workspace) => w.pid);
    //@ts-ignore
    return this.me.workspaces.find(workspace => workspace.pid === this.currentWorkspacePid && !firstThreePids.includes(workspace.pid));
  }

  get isInRoom() {
    return !!this.$route.params.board_pid;
  }
  
  shouldShowTwoLetters(workspaceName: string) {
    const firstLetter = workspaceName.trim()[0].toLowerCase();
  
    const count = this.me.workspaces.reduce((acc: any, workspace: any) => {
      if(workspace.name.trim()[0].toLowerCase() === firstLetter) {
        acc += 1;
      }
      return acc;
    }, 0);

    // If more than one workspace starts with the same letter, return true
    return count > 1;
  }

  handleChecklistVisibility(manual: boolean) {
    if (manual) {
     
      // Manual toggle:
      if (this.activeBox === 'checklist') {
        this.$store.commit('clear_completed_checklist_items');
        this.activeBox = null;
        if(this.onboarding === 3) {
          this.$store.commit('set_expanded_onboarding', null);
        }
      } else {
        this.activeBox = 'checklist';
        this.$gtag.event('pmb_user_opened_checklist');
      }
      this.manualOpen = true;

    } else {
      // Programmatic action: 
      this.manualOpen = false;
      this.activeBox = 'checklist';
      

      setTimeout(() => {
        this.fadeOutNotification = true;
        setTimeout(() => {
          this.activeBox = '';
          this.fadeOutNotification = false;
          this.$store.commit('clear_completed_checklist_items');
        }, 700); 
      }, 5000); 

    }

    if (this.showChecklistPrompt) {
      this.$store.commit('set_show_checklist_prompt', false);
    }
    if (this.showActivityProgress) {
      this.$store.commit('set_show_activity_progress', false);
    }
  }

  handleOutsideClick() {
    if(this.onboarding === 3) {
      this.$store.commit('set_expanded_onboarding', null);
    }
    this.$store.commit('clear_completed_checklist_items');
    this.handleChecklistVisibility(true);
  }

  switchToFullView() {
    if(!this.manualOpen) {
      this.manualOpen = true;
    } else { return }
  }

  openUserProfile() {
    this.$buefy.modal.open({
      component: UserProfile,
      // @ts-ignore
      customClass: 'is-paddingless user_profile_modal',
      width: '700px',
      parent: this,
    });
  }

  openSwitchWorkspace() {
    this.$buefy.modal.open({
      component: MyWorkspacesList,
      width: '420px',
      customClass: 'my-workspaces-modal',
      parent: this,
    });
  }

  openWorkspace(workspace: Workspace) {
    // If user clicked on different WS route to WS
    if (this.$route.params.workspace_pid !== workspace.pid) {
      this.$store.commit('set_active_workspace_access', workspace.my_access);
      if (workspace.my_access == 0) {
        this.$router.push({ name: 'dashboard-layout', params: { workspace_pid: workspace.pid } }).catch(() => {});
      } else {
        this.$store.commit('set_workspace', workspace);
        this.$store.commit('set_active_workspace_pid', workspace.pid);
        this.$router.push({ name: 'WorkspaceBlocked' });
      }
    } else {
      // If user clicked on same WS route back to previous room
      if (this.$route.name !== 'home') {
        this.$router.push({ name: 'home', params: { from: 'workspace_switcher' } });
      } else if (this.$route.name === 'home' && this.boardIsInCurrentWorkspace) {
        this.$router.push({ name: 'room', params: { board_pid: this.lastActiveBoardPid } });
        // If previous room is in different WS, route to the first room in the current WS
      } else { 
        let firstBoardPid = this.workspace.boards[0].pid;
        this.$router.push({ name: 'room', params: { board_pid: firstBoardPid } });
      }
    }
  }

  goToWorkspaceCreate() {
    this.$gtag.event('pmb_create_new_workspace_initiated');
    this.$router.push('/create-workspace');
  }

  openPaywall() {
    this.$buefy.modal.open({
      component: Paywall,
      // @ts-ignore
      customClass: 'split-modal',
      parent: this,
    });
  }

  // mounted() {
  //   if(this.onboarding === 1) {
  //     this.activeBox = 'checklist'
  //     this.$store.commit('set_expanded_onboarding', 2);
  //   }
  // }


  get hasStatus() {
    if(this.me.status) {
      return this.me.status.emoji;
    } else {
      return null;
    }
  }

  get lastActiveBoardPid() {
    return this.$store.state.last_active_board_pid;
  }

  get boardIsInCurrentWorkspace() {
    return this.workspace.boards.some((board: any) => board.pid === this.lastActiveBoardPid);
  }

  get planList() {
    return this.$store.state.plans;
  }

  // get canAddNewWorkspace() {
  //   if(this.$store.state.workspace.currentSubscription && this.$store.state.workspace.currentSubscription.hasAccess && this.$store.state.workspace.currentSubscription.plan.planAccess.max_board > this.me.workspaces.length) {
  //     return true;
  //   }else if(this.planList[0].planAccess.max_workspace > this.me.workspaces.length) {
  //     return true;
  //   }
  //   return false;
  // }

  get hasUnreadNotification() {
    let unReadNotification = this.$store.state.me.notifications.data.filter((noti: any) => {
      return noti.read_at == null;
    });
    if(unReadNotification.length > 0){
      this.switchFavicon(true)
      return true;
    } else {
      this.switchFavicon(false)
      return false;
    }
  }

  switchFavicon(isNotifyFavicon: boolean) {
    const faviconElement = document.getElementById('favicon') as HTMLLinkElement;
    if (!faviconElement) {
      // Favicon element with id "favicon" not found
      return;
    }
    if (!isNotifyFavicon) {
      // Switch to the default favicon with a unique query string
      faviconElement.href = window.location.origin + '/favicon.ico?v=' + new Date().getTime();
    } else {
      // Switch to the "notify" favicon with a unique query string
      faviconElement.href = window.location.origin + '/favicon-notify.ico?v=' + new Date().getTime();
    }
  }

  openStatusModal() {
    this.$buefy.modal.open({
      component: UserStatus,
      // @ts-ignore
      customClass: 'is-paddingless',
      width: '520px',
      parent: this,
    });
  }

  @Watch('showActivityProgress')
  onActivityProgress() {
    if (this.showActivityProgress) {
      setTimeout(() => {
        this.$store.commit('set_show_activity_progress', false);
      }, 5000); 
    }
  }
}
