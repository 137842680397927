

































import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class OnboardingNavigator extends Vue {
  get currentStep() {
    return this.$route.query.step;
  }

  get currentBoardPid() {
    return this.$store.state.board.pid;
  }

  get currentlyOnboarding() {
    return this.$store.state.currently_onboarding;
  }

  get hasRooms() {
    return this.$store.state.workspace.boards && this.$store.state.workspace.boards.length >= 1;
  }

  get allowCancelCreate() {
    if(this.$store.state.currently_onboarding && !this.hasRooms) {
      return false;
    } else {
      return true;
    }
  }

  changeStep(step: any) {
    if(step === 4) {
      this.$router.push({name: 'board-layout', params: { board_pid: this.currentBoardPid } })
    } else {
      this.$router.replace({ query: { step: step }})
    }
  }
}
