

































































import {Component, Vue} from 'vue-property-decorator';
import AddGoalsFromTemplate from "@/components/templates/AddGoalsFromTemplate.vue";
import VerifyUser from "@/components/auth/VerifyUser.vue";
import InRoomActivation from "@/components/auth/register/InRoomActivation.vue";


@Component({
  components: {InRoomActivation},
})
export default class UserActivation extends Vue {
  step: number | any = 1;
  staticStep: number | any = 1;
  isTutorialModalActive = false;
  activationStep: number = 0;

  get hasAddedNorthStars() {
    return this.$store.state.newlyCreatedNorthstarsCountInWorkspace >= 1;
  }

  get hasAddedObjectives() {
    return this.$store.state.onboarding_activities_added;
  }

  get isOnGuestAccount() {
    return this.$store.state.me.isFake;
  }

  get isFromAd() {
    return this.$store.state.user_from_ad
  }


  toggleStep(direction: string) {
    if(direction === 'back' && this.step >= 2) {
      this.step--;
    } else {
      this.step++;
    }
  }

  manuallyCompleteActivation() {
    this.$gtag.event('pmb_app_activation_completed_manually');
    this.completeActivation();
  }

  skipActivation() {
    this.$gtag.event('pmb_app_activation_skipped_manually');
    this.completeActivation();
  }

  completeActivation() {
    this.$gtag.event('pmb_app_activation_completed_manually');
    this.$store.commit('set_currently_onboarding', false);
    this.$store.commit('set_currently_onboarding_in_room', false);
    this.$store.commit('set_onboarding_activities_added', false);
   
    this.$store.commit('show', false);
    this.$router.push({
      name: 'room', params: {
        board_pid: this.$store.state.board.pid,
        workspace_pid: this.$store.state.active_workspace_pid,
      }
    });
    if(this.isOnGuestAccount) {
      this.claimAccount();
    }
  }

  claimAccount() {
    this.$gtag.event('pmb_user_claim_account_manually_initiated_create_invite');
    this.$buefy.modal.open({
      component: VerifyUser,
      props: {
        claimWs: (this.$store.state.me.isFake) ?? false,
        clickedClaim: true
      },
      width: '500px',
      canCancel: false,
      parent: this,
    });
  }

  openActivationModal() {
    this.$buefy.modal.open({
      component: InRoomActivation,
      //@ts-ignore
      customClass: 'add_goal_from_onboarding',
      parent: this,
    });
  }

  openAddNorthStar() {
    this.$buefy.modal.open({
      component: AddGoalsFromTemplate,
      //@ts-ignore
      customClass: 'add_goal_from_template',
      parent: this,
      props: {
        startDate: new Date()
      }
    });
  }

  mounted() {
    this.$gtag.event('pmb_app_activation_mounted');
    if(this.$route.query.activation) {
      let stepFromUrl = this.$route.query.activation;
      //@ts-ignore
      this.step = parseInt(stepFromUrl);
    }
    if(!this.hasAddedNorthStars) {
      this.openActivationModal();
    }
  }
}
