








































import {Component, Vue} from 'vue-property-decorator';
import {UpdateUserAccountType} from "@/graphql/auth";

@Component({
  components: {},
})
export default class UserType extends Vue {

  userAccountType: string | null = null;
  isLoading: boolean = false;

  updateUserAccountType(type: string) {
    if(this.isLoading) {
      return
    }
    this.userAccountType = type;
    this.isLoading = true;
    this.$gtag.event('pmb_app_onboarding_user_type_' + type);
    this.$apollo
      .mutate({
        mutation: UpdateUserAccountType,
        variables: {
          account_type: this.userAccountType
        },
      })
      .then((_data: any) => {
        this.$gtag.event('pmb_app_onboarding_account_type_selected');
        this.$store.commit('set_currently_onboarding_in_room', false);
        this.$store.commit('set_onboarding_step', 1);
        this.closeModal()
      })
      .catch((e: any) => {
        Vue.prototype.$consoleLog(e)
      })
  }


  closeModal() {
    // @ts-ignore
    this.$parent.close() 
  }
}
