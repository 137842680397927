

















































































import {Component, Vue} from 'vue-property-decorator';
import WorkspaceAvatar from "@/components/workspace/WorkspaceAvatar.vue";
import {Workspace} from "@/typescript/types";

@Component({
  components: {WorkspaceAvatar},
})
export default class MyWorkspacesList extends Vue {

  get me() {
    return this.$store.state.me;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get activeWorkspacePid() {
    return this.$store.state.active_workspace_pid;
  }
  get currentWorkspace() {
    return this.$store.state.workspace;
  }

  openWorkspace(workspace: Workspace) {
    if(this.$route.params.workspace_pid !== workspace.pid) {
      this.$store.commit('set_active_workspace_access', workspace.my_access);
      if(workspace.my_access == 0){
        this.$router.push({ name: 'dashboard-layout', params: { workspace_pid: workspace.pid } }).catch(()=>{});
      }else{
        this.$store.commit('set_workspace', workspace);
        this.$store.commit('set_active_workspace_pid', workspace.pid);
        this.$router.push({ name: 'WorkspaceBlocked'});
      }
    }
    //@ts-ignore
    this.$parent.close();
  }

  goToWorkspaceCreate() {
    this.$gtag.event('pmb_create_new_workspace_initiated');
    this.$router.push('/create-workspace');
    //@ts-ignore
    this.$parent.close();
  }

}
