










































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class OnboardingVideoTutorial extends Vue {
  videoPlayer: HTMLVideoElement | null = null;
  hasWatchedVideo: boolean = false;
  isVideoPlaying: boolean = false;

  mounted() {
    this.videoPlayer = this.$refs.videoPlayer as HTMLVideoElement;

    this.playVideo()
    this.$gtag.event('pmb_user_mounted_tutorial_video');
  }

  playVideo() {
    this.videoPlayer?.play();
    this.isVideoPlaying = true;
  }

  pauseVideo() {
    this.videoPlayer?.pause();
    this.isVideoPlaying = false;
  }

  replayVideo() {
    if (this.videoPlayer) {
      this.videoPlayer.currentTime = 0;
      this.videoPlayer.play();
    }
    this.hasWatchedVideo = false;
    this.isVideoPlaying = true;
    this.$gtag.event('pmb_user_replayed_tutorial_video');
  }

  onVideoPlay() {
    this.isVideoPlaying = true; // Ensure state is updated when video starts playing
  }

  onVideoPause() {
    this.isVideoPlaying = false; // Ensure state is updated when video is paused
  }

  onVideoEnd() {
    console.log('video ended')
    this.hasWatchedVideo = true;
    this.$gtag.event('pmb_user_watched_tutorial_video');
  }

  closeModal() {
    // @ts-ignore
    this.$parent.close() 
  }

}
