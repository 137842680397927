

































import {Component, Vue, Prop} from 'vue-property-decorator';
import WorkspaceSettingsIndex from "@/components/settings/workspace/WorkspaceSettingsIndex.vue";
import WorkspacePlanManager from "@/components/workspace/WorkspacePlanManager.vue";
import MembersManager from "@/components/workspace/MembersManager.vue";
import OnboardingVideoTutorial from "../OnboardingVideoTutorial.vue"

@Component({
  components: {MembersManager, WorkspacePlanManager, WorkspaceSettingsIndex},
})
export default class WorkspaceManagerIndex extends Vue {
  activeTab: string | null = 'settings';

  @Prop({default: false, required: false})
  fromManageMemberButton!: boolean

  mounted() {
    this.setDefualtTab()

    if(this.fromManageMemberButton) {
      this.activeTab = 'members'
    }
   
    this.$events.listen('close', (_) => {
      //@ts-ignore
      this.$parent.close();
    })
  }

  openWorkspacePinned() {
    this.$router.push({ name: 'pinned'});
    this.$nextTick(function () {
      //@ts-ignore
      this.$parent.close();
    });
  }

  openWorkspaceFiles() {
    this.$router.push({ name: 'my-files'});
    this.$nextTick(function () {
      //@ts-ignore
      this.$parent.close();
    });
  }

  openTutorialVideo() {
    this.$buefy.modal.open({
      component: OnboardingVideoTutorial,
      width: '1150px',
      canCancel: true,
      parent: this,
      customClass: 'video-tutorial'
    });
  }

  get planList() {
    return this.$store.state.plans;
  }

  get workspaceSubscription() {
    return this.$store.state.workspace.currentSubscription;
  }

  get currentPlan() {
    if(this.workspaceSubscription && !this.workspaceSubscription.hasAccess) {
      return this.planList[0];
    }else if(this.workspaceSubscription == null) {
      return this.planList[0];
    }
    return this.workspaceSubscription.plan;
  }

  get isAdminOfWorkspace() {
    return this.$store.getters.isAdminOfWorkspace;
  }

  setDefualtTab() {
    if(this.isAdminOfWorkspace) {
      this.activeTab = 'settings';
    }else {
      this.activeTab = 'plan';
    }
  }

  closeModal() {
    //@ts-ignore
    this.$parent.close();
  }

}
