





































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {PublicBoardTemplates} from "@/graphql/boardTemplate";
import AddOrLinkNorthStar from "@/components/northstars/AddOrLinkNorthStar.vue";
import CreateGoalTips from "@/components/general/CreateGoalTips.vue";
import NorthStarService from "@/NorthStarService";
import {PublicBoardTemplatePaginator} from "@/typescript/types";
//@ts-ignore
import VLazyImage from "v-lazy-image/v2";

@Component({
  components: {CreateGoalTips, AddOrLinkNorthStar, VLazyImage},
  apollo: {
    PublicBoardTemplates: {
      query: PublicBoardTemplates,
      variables() {
        return {
          page: this.publicBoardTemplatesPageCount
        }

      },
      result(result) {
       
        if (result && result.data && result.data.PublicBoardTemplates && this.PublicBoardTemplatesData && this.PublicBoardTemplatesData.data.length > 0) {
          this.PublicBoardTemplatesData.data  = this.filterDuplicateData([...this.PublicBoardTemplatesData.data, ...result.data.PublicBoardTemplates.data]);
          this.PublicBoardTemplatesData.paginatorInfo = result.data.PublicBoardTemplates.paginatorInfo;

        }else{
          this.PublicBoardTemplatesData = result.data.PublicBoardTemplates;
        }
      },
    },
  }
})
export default class AddGoalsFromTemplate extends Vue {
  activeGoal: Object | null = null;
  minimize: boolean = false;
  startFromScratch: boolean = false;
  showTemplateDescriptions: boolean = false;
  service: NorthStarService | null = null;
  addedBoard: string | null = null;
  selectedSuggestions: Array<string> = [];
  goalAddDisabled: boolean = true;
  manuallyAddedGoal: boolean = false;

  publicBoardTemplatesPageCount: number = 1;

  PublicBoardTemplatesData: PublicBoardTemplatePaginator | null = null;


  @Prop({default: null, required: false})
  startDate!: Date;

  get workspace() {
    return this.$store.state.workspace;
  }

  get board() {
    return this.$store.state.board;
  }

  get currentBoardTemplateId() {
    if(this.board.board_template) {
      return this.board.board_template.id;
    }
    return null;

  }

  


  ifAlreadySelected(northStarId: string) {
    return this.selectedSuggestions.includes(northStarId);
  }

  mounted() {
    this.$gtag.event('pmb_app_create_northstar_modal_mounted');
    this.service = new NorthStarService(this.$apollo);

    if(this.currentBoardTemplateId) {
      this.templateItemClicked(this.currentBoardTemplateId);
    }

    if(this.board) {
      this.addedBoard = this.board.id;
    }
  }

  templateItemClicked(templateId: string) {
    this.minimize = false;
    this.$nextTick(function(){
      //@ts-ignore ToDo: fix type issue
      window.location.href = '#' + templateId;
    });
  }

  manuallyAdded() {
    setTimeout(() => {
      //@ts-ignore
      this.$parent.close();
    }, 400);

  }

  publicBoardTemplatesLoadMore() {
    this.publicBoardTemplatesPageCount++;
    this.$apollo.queries.PublicBoardTemplates.refetch();
  }

  filterDuplicateData(arr: Array<any>) {
    return arr.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
  }

  copyNorthstar(northstar_id: string) {
    this.$gtag.event('pmb_create_ns_from_templates');

    if(this.ifAlreadySelected(northstar_id)) {
      return
    }
    if(this.$store.state.board) {
      this.addedBoard = this.$store.state.board.id;
    }
    if(this.selectedSuggestions.length == 0 || this.addedBoard != null) {
      this.selectedSuggestions.push(northstar_id);
      this.service?.copyNorthStar(northstar_id, this.addedBoard, this.workspace.id, 'not_all').then((res: any) => {
        if(res.data.copyNorthStar && res.data.copyNorthStar.boards.length > 0) {
          this.addedBoard = res.data.copyNorthStar.boards[0].id;
          this.$store.commit('set_newly_created_northstar_count', (this.$store.state.newlyCreatedNorthstarsCountInWorkspace == null) ? 1 : this.$store.state.newlyCreatedNorthstarsCountInWorkspace + 1)
          this.$events.fire('northstars-updated-my-northstar', res.data.copyNorthStar);
          this.$buefy.toast.open({
            message: 'Goal added successfully',
            position: 'is-bottom-right',
            type: 'is-black',
          });
        }
      })
    }

  }
}
